export const burnout = {
  Name: 'burnout',
  Landing: {
    heading: 'Overcome Burnout & Chronic Fatigue - Be Motivated Again!',
    description:
      'Take a quick test to receive a personalized plan to improve your mental health and start your journey to a healthier and happier you.',
  },
  Quiz: {
    quizFlow: [
      {
        image: '/static/icons/1.svg',
        question: 'What is your age?',
        questionCode: 'age',
        subtitle: 'This information will be used to calculate your burnout risk.',
        answers: [
          '18 - 24 years',
          '25 - 34 years',
          '35 - 44 years',
          '45 - 54 years',
          '55+ years',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/4.svg',
        question: 'What are your main goals?',
        questionCode: 'goals',
        subtitle: 'Select as many or few options as you like.',
        answers: [
          'Focus better',
          'Reduce stress',
          'Eliminate overthinking',
          'Prevent burnout',
          'Improve sleep',
          'Boost self-esteem',
          'Feel happier',
          'Boost energy',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/2.svg',
        question: 'Do you frequently compare yourself with others?',
        questionCode: 'compare',
        answers: ['Yes', 'No', 'Rarely'],
        type: 'question',
      },
      {
        image: '/static/icons/4.svg',
        question: 'Do You experience any of these symptoms?',
        questionCode: 'symptoms',
        subtitle: 'Select as many or few options as you like.',
        answers: [
          'Loss of interest in activities.',
          'Irritability, frustration or pessimism.',
          'Fatigue or decreased energy.',
          'Difficulty thinking clearly or quickly.',
          'Exhaustion caused by constantly feeling swamped.',
          'Appetite or weight changes.',
          'Thinking over the past and worrying about the future.',
          'None',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/9.svg',
        questionCode: 'struggle',
        question: 'What is your current biggest struggle?',
        answers: [
          'Anxiety',
          'Lack of Motivation',
          'Social isolation',
          'Depression',
          'Overthinking',
          'Relationships',
          'PTSD',
          'ADHD',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/3.svg',
        questionCode: 'sleep',
        question: 'How well do you sleep?',
        answers: ['Good', 'Average', 'Bad'],
        type: 'question',
      },
      {
        type: 'info',
        description:
          "Feeling tired, burnt out, and unmotivated? Remember, these are signs your mind and body are signalling for a break. Often, the relentless pace of life pushes us into a corner, making us feel trapped and overwhelmed.\n\nBut here's an essential insight: refuelling isn't just about rest. It's about understanding the root of your exhaustion.\n\nRecognizing what's draining you is the first step. With that knowledge, targeted strategies can rejuvenate your spirit and propel you forward with renewed zest!",
        button: 'Continue',
      },
      {
        image: '/static/icons/4.svg',
        question: 'How would you rate your current eating habits?',
        questionCode: 'eating_habits',
        answers: [
          'I mostly eat well.',
          'I have a poor appetite.',
          'I struggle from emotional eating.',
          'I struggle from overeating.',
          'I have an eating disorder (anorexia, bulimia, etc.)',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/3.svg',
        questionCode: 'difficult_relax',
        question: 'Do you find it difficult to relax?',
        answers: ['Yes', 'No', 'Rarely'],
        type: 'question',
      },
      {
        image: '/static/icons/1.svg',
        question: 'Do you love your job?',
        questionCode: 'job_love',
        answers: [
          'Yes, I do love my job.',
          'I’m fine with my job.',
          'I am underpaid.',
          'I have stopped growing professionally.',
          'I am unemployed.',
          'I hate my job.',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/4.svg',
        question: 'Do any of the following describe you?',
        questionCode: 'describe_you',
        subtitle: 'Select as many or few options as you like.',
        answers: [
          'I have a negative self-image and often negative self-talk.',
          'I compare myself to others.',
          'I blame myself when things go wrong.',
          'I don’t like how I look.',
          'I think other people are better than me.',
          'I focus on my failures and ignore my achievements.',
          'None',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/2.svg',
        question: 'How easily distracted are you?',
        questionCode: 'distraction',
        answers: [
          'Easily distracted',
          'Sometimes lose focus',
          'Rarely lose focus',
          'Always laser-focused',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/4.svg',
        question: 'Do You experience any of these symptoms?',
        questionCode: 'characteristics',
        subtitle: 'Select as many or few options as you like.',
        answers: [
          'Loss of interest in activities',
          'Irritability, frustration or pessimism',
          'Fatigue or decreased energy',
          'Difficulty thinking clearly or quickly',
          'Exhaustion caused by constantly feeling swamped',
          'Appetite or weight changes',
          'Thinking over the past and worrying about the future',
          'None',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/icons/brenda.png',
        heading: 'NEXT LEVEL SELF-CARE!',
        description:
          '"I used to feel burnt out and powerless, but Minderse gave me the tools to change that. My work-life balance has greatly improved, and I now feel comfortable taking time for myself and prioritizing my well-being.\r\n\r\nIf you\'re struggling with burnout, I highly recommend giving Minderse a try."',
        button: 'Continue',
      },
      {
        question: 'Do you have any habits that you would like to stop or break?',
        questionCode: 'want_to_stop',
        subtitle: 'Select as many options as you wish.',
        answers: [
          {
            image: '/static/icons/alcohol.png',
            text: 'Drinking',
          },
          {
            image: '/static/icons/social-media.png',
            text: 'Social media',
          },
          {
            image: '/static/icons/sad.png',
            text: 'Insecurity',
          },
          {
            image: '/static/icons/hamburger.png',
            text: 'Fast food',
          },
          {
            image: '/static/icons/clock.png',
            text: 'Losing sleep',
          },
          {
            image: '/static/icons/biting.png',
            text: 'Nail-biting',
          },
          {
            image: '/static/icons/television.png',
            text: 'Binge watching',
          },
          {
            image: '/static/icons/none.png',
            text: 'None/Other',
          },
        ],
        isImageType: true,
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/1.svg',
        question:
          'What is the amount of time you are willing to commit to self-care and burnout prevention on a daily basis?',
        questionCode: 'amount_of_daily_time',
        answers: ['5 min / day', '10 min / day', '15 min / day', '20+ min / day'],
        type: 'question',
      },
      {
        type: 'email',
      },
    ],
  },
  GetStarted: {
    description:
      'We have created a personalized plan specifically for you to overcome burnout and achieve your goals.',
    button: 'Continue',
  },
  Summary: {
    heading: 'End burnout, shake off fatigue and achieve your dreams!',
    resultsTitle: 'Emotional fatigue level',
    suggestedPlan: 'Balance & Revive',
    issue: 'excessive stress and fatigue',
    summary: [
      {
        heading: 'Feeling overwhelmed',
        description:
          'It seems that you may be struggling to balance your responsibilities and commitments, causing feelings of burnout and exhaustion.',
      },
      {
        heading: 'Lack of motivation and energy',
        description:
          'Based on your responses, you may be struggling with a lack of motivation and energy, potentially due to burnout.',
      },
      {
        heading: 'Decreased productivity',
        description:
          'It appears that you may be experiencing a decrease in productivity and difficulty focusing, which could be indicative of burnout.',
      },
      {
        heading: 'Negative emotions',
        description:
          'Your responses suggest that you may be struggling with negative thoughts and emotions, which could be a sign of burnout.',
      },
    ],
  },
  Purchase: {
    suggestedPlan: 'self-care and stress management',
    promise: 'prevent burnout and improve overall well-being',
    benefits: [
      {
        heading: 'Increase resilience.',
        description:
          'Develop the ability to overcome challenges and handle difficult situations with ease.',
      },
      {
        heading: 'Enhance productivity.',
        description:
          'Achieve more in less time by utilizing effective time management techniques.',
      },
      {
        heading: 'Strengthen perseverance.',
        description: 'Develop strategies to stay motivated and avoid burnout.',
      },
      {
        heading: 'Reduce stress and overwhelm.',
        description:
          'Learn to recognize and manage triggers to maintain a sense of calm and balance.',
      },
      {
        heading: 'Transform into your most productive self.',
        description:
          'Focus on sustainable habits and strategies for a more fulfilling work life.',
      },
      {
        heading: 'Improve professional relationships.',
        description:
          'Develop stronger communication skills and empathy to foster better working relationships.',
      },
    ],
    chart: {
      start: 'Your Burnout Level',
      end: 'Your Target Level',
    },
    newspaper:
      'Our personalized plan will help you learn effective strategies for managing your responsibilities and maintaining a healthy work-life balance.\n\nTake control of your life and start thriving today.',
    scienceBacked: [
      {
        image: '/static/icons/bed.png',
        heading: 'Improved sleep quality',
        description:
          'Burnout can seriously disrupt your sleep and significantly reduce sleep quality by at least 36%.',
      },
      {
        image: '/static/icons/relieved.png',

        heading: 'Reduced stress and anxiety',
        description:
          'Overcome feelings of overwhelm and exhaustion with a personalized plan to help you manage your workload and responsibilities more effectively.',
      },
      {
        image: '/static/icons/cash.png',
        heading: 'Earn more money',
        description:
          'Taking care of mental health leads to increased productivity, better decision making, and improved relationships, leading to greater success and earning potential.',
      },
      {
        image: '/static/icons/brain.png',
        heading: 'Increased cognitive ability',
        description:
          'Eliminating burnout has been shown to increase cognitive processing power, according to a study conducted by scientists in Utah.',
      },
    ],
  },
};

export const dopamine = {
  Name: 'dopamine',
  Landing: {
    heading: "The last plan you'll ever need to break the cycle of dopamine addiction",
    description:
      "\"It's an incredible tool for anyone dealing with dopamine addiction. I've been using it for a few months now, and I'm beyond thrilled with the results. It only takes me 10-15 minutes a day...\" - Susan",
  },
  Quiz: {
    quizFlow: [
      {
        type: 'landing',
      },
      {
        image: '/static/icons/1.svg',
        question: 'What is your age?',
        questionCode: 'age',
        subtitle: 'This information will be used to calculate your self-esteem score.',
        answers: [
          '18 - 24 years',
          '25 - 34 years',
          '35 - 44 years',
          '45 - 54 years',
          '55+ years',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/2.svg',
        questionCode: 'distraction',
        question: 'How easily distracted are you?',
        subtitle: 'This helps us understand the strength of your brain connections.',
        answers: [
          'Easily distracted',
          'Occasionally lose focus',
          'Rarely lose focus',
          'Very focused',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/3.svg',
        questionCode: 'social_media',
        question: 'How often do you feel the need to check your phone or social media?',
        answers: [
          'Almost always',
          'Every few hours',
          'Once or twice a day',
          'Rarely or never',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/4.svg',
        questionCode: 'surf_youtube',
        question: 'How often do you surf YouTube or other social media while eating?',
        answers: ['Almost always', 'Often', 'Rarely', 'Almost never'],
        type: 'question',
      },
      {
        image: '/static/icons/5.svg',
        questionCode: 'last_minute',
        question: 'Do you often leave things to the last minute?',
        answers: ['Never', 'Rarely', 'Often', 'All the time'],
        type: 'question',
      },
      {
        image: '/static/icons/6.svg',
        questionCode: 'energy_levels',
        question:
          'I usually have more energy for my tasks if I wait until the last minute',
        answers: ['Almost never', 'Sometimes', 'Almost always'],
        type: 'question',
      },
      {
        image: '/static/icons/7.svg',
        questionCode: 'first_thing',
        question: 'What do you usually do first thing in the morning?',
        answers: [
          'Picking up my phone',
          'Making coffee',
          'Brushing teeth & Taking Shower',
          'Other',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/8.svg',
        questionCode: 'energy_2',
        question:
          'I don’t have energy to get through the day no matter how much sleep I got',
        answers: ['Almost always', 'Often', 'Rarely', 'Almost never'],
        type: 'question',
      },
      {
        image: '/static/icons/9.svg',
        questionCode: 'procrastination',
        question: 'Do you feel guilty or ashamed when you procrastinate?',
        answers: ['Almost always', 'Often', 'Rarely', 'Almost never'],
        type: 'question',
      },
      {
        image: '/static/icons/1.svg',
        questionCode: 'sport',
        question: 'How much time do you dedicate to physical activity each week?',
        answers: ['0-2 hours', '3-5 hours', '6-8 hours', 'More than 8 hours'],
        type: 'question',
      },
      {
        image: '/static/icons/2.svg',
        questionCode: 'motivation',
        question:
          'I no longer feel motivated to engage in activities that used to bring me joy',
        answers: ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'],
        type: 'question',
      },
      {
        image: '/static/icons/3.svg',
        questionCode: 'driven_motivated',
        question: 'When was the last time you felt driven and motivated?',
        answers: [
          'A few weeks ago',
          'Less than a year ago',
          '1 to 2 years ago',
          'More than 3 years ago',
          'Never in my life',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/4.svg',
        questionCode: 'stressed_anxious',
        question: 'How often do you feel stressed or anxious?',
        answers: ['Never', 'Rarely', 'Often', 'All the time'],
        type: 'question',
      },
      {
        image: '/static/icons/5.svg',
        questionCode: 'overwhelemd_by_work',
        question: 'Do you often feel overwhelmed by the amount of work you have to do?',
        answers: ['Almost always', 'Often', 'Rarely', 'Almost never'],
        type: 'question',
      },
      {
        image: '/static/icons/6.svg',
        question: 'What do you want to get out of this dopamine management journey?',
        questionCode: 'what_to_get',
        subtitle: 'Choose all the options that apply to you:',
        answers: [
          'Improve my overall mental health',
          'Improve my relationships with other',
          'Set achievable goals',
          'Perform better at work',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/7.svg',
        question: "Are there aspects of your mental health you'd like to address?",
        questionCode: 'what_to_address',
        subtitle: 'Choose all the options that apply to you:',
        answers: [
          'Stress',
          'Mood fluctuations',
          'Anxiety',
          'Low mood',
          'Low energy',
          'Poor self-image',
          "I'm totally fine",
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/8.svg',
        question: 'Is there anything you want to improve about your sleep?',
        questionCode: 'sleep',
        subtitle: 'Choose all the options that apply to you:',
        answers: [
          'Difficulty falling asleep',
          'Waking up tired',
          'Waking up during the night',
          'Unstable sleep schedule',
          'Insomnia',
          'I sleep well',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/9.svg',
        question:
          'In order to live a dopamine addiction-free life, what do you think you need to improve?',
        questionCode: 'what_improve',
        subtitle: 'Choose all the options that apply to you:',
        answers: [
          'My state of calm',
          'My focus levels',
          'My willpower',
          'My energy levels',
          'My mental strength',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/1.svg',
        question: 'Are there any habits or activities that you tend to procrastinate on?',
        questionCode: 'habits_activities',
        subtitle: 'Choose all the options that apply to you:',
        answers: [
          'Exercising',
          'Health check-ups',
          'Drinking water',
          'Reading',
          'Studying',
          'Cleaning',
          'Social life',
          'Eating healthily',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        question: 'Do you have any habits that you’d like to quit?',
        questionCode: 'habits_to_quit',
        subtitle: 'Choose all the options that apply to you:',
        answers: [
          {
            image: '/static/icons/alcohol.png',
            text: 'Drinking alcohol',
          },
          {
            image: '/static/icons/social-media.png',
            text: 'Screen time',
          },
          {
            image: '/static/icons/sad.png',
            text: 'Delaying tasks',
          },
          {
            image: '/static/icons/hamburger.png',
            text: 'Eating junk food',
          },
          {
            image: '/static/icons/clock.png',
            text: 'Not enough sleep',
          },
          {
            image: '/static/icons/biting.png',
            text: 'Biting nails',
          },
          {
            image: '/static/icons/doubt.png',
            text: 'Doubting myself',
          },
          {
            image: '/static/icons/none.png',
            text: 'None or other',
          },
        ],
        isImageType: true,
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/2.svg',
        question: 'Are there any habits or activities that you tend to procrastinate on?',
        questionCode: 'habits_activities',
        subtitle: 'Choose all the options that apply to you:',
        answers: [
          'Exercising',
          'Health check-ups',
          'Drinking water',
          'Reading',
          'Studying',
          'Cleaning',
          'Social life',
          'Eating healthily',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/3.svg',
        questionCode: 'knowledge',
        question: 'How much do you know about the negative impact of dopamine levels?',
        answers: ['Nothing at all', 'Not that much', 'A lot'],
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/icons/brain3.png',
        maxWidth: '50%',
        heading: 'Manage dopamine to unlock a happy and fulfilling life',
        description:
          'Excessive and uncontrolled dopamine is the leading cause of procrastination, low self-esteem, lack of motivation, fatigue, and feelings of hopelessness.',
        button: 'Continue',
      },
      {
        image: '/static/icons/4.svg',
        question:
          'How do you think your life will improve if you let go of dopamine addiction?',
        questionCode: 'will_improve',
        subtitle: 'Choose all the options that apply to you:',
        answers: [
          'Lose weight',
          'Break free from survival mode',
          'Enjoy simple things again',
          'Beat procrastination',
          'Achieve financial stability',
          'Other',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/5.svg',
        question: 'How motivated are you to to make a real change in your life?',
        questionCode: 'how_motivated',
        answers: [
          "I'm determined to work on my energy and dopamine levels",
          'I really want to but am afraid to fail again',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/icons/stats.png',
        heading: 'Ready to see your plan?',
        description:
          "Minderse's behavior change plans are grounded in the latest behavioral science research and expertly crafted by psychologists, coaches, and behavioral specialists.\r\n\r\nOur personalized approach targets the underlying causes of behavior, enabling long-term, sustainable habit change.",
        button: 'Continue',
      },
      {
        image: '/static/icons/6.svg',
        question: 'How much time would you like to spend on self-development each day?',
        subtitle:
          'You only have to dedicate a few minutes per day to letting go of bad habits and adopting good ones!',
        questionCode: 'daily_commitment_time',
        answers: [
          '5 minutes per day',
          '10 minutes per day',
          '15 minutes per day',
          'More than 20 minutes per day',
        ],
        type: 'question',
      },
      // {
      //   type: 'email',
      // },
    ],
  },
  GetStarted: {
    description:
      'We have created a personalized plan specifically for you to break the cycle of dopamine addiction and achieve your goals.',
    button: 'Continue',
  },
  Summary: {
    heading: 'Discover your personal growth plan',
    resultsTitle: 'Harmful dopamine level',
    suggestedPlan: 'Dopamine Management',
    issue: 'harmful dopamine level',
    summary: [
      {
        heading: 'Rejection anxiety',
        description:
          'You may be limiting yourself and holding yourself back because of a fear of being rejected.',
      },
      {
        heading: 'Overwhelming self-doubt',
        description:
          'Based on your scores, it seems that you have low self-confidence in yourself and your abilities.',
      },
      {
        heading: 'Perfectionism',
        description:
          'It appears that you might be a perfectionist and are never quite satisfied with your work.',
      },
      {
        heading: 'Powerful inner critic',
        description:
          'You frequently criticize yourself harshly for any deviations from your plans.',
      },
    ],
  },
  Purchase: {
    suggestedPlan: 'Dopamine Management',
    promise: 'reduce dopamine addiction',
    subtitle:
      'Feel more confident and secure, form healthy bonds, reduce anxious thoughts',
    benefits: [
      {
        heading: 'Build confidence.',
        description:
          'Develop a positive self-image and the skills to overcome obstacles with confidence.',
      },
      {
        heading: 'Boost your efficiency.',
        description: 'Achieve goals faster and easier by leveraging your strengths.',
      },
      {
        heading: 'Strengthen determination.',
        description: 'Develop effective planning strategies and self-control mechanisms.',
      },
      {
        heading: 'Reduce stress and anxiety.',
        description: 'Learn to identify and manage stressors and overcome anxiety.',
      },
      {
        heading: 'Transform into your best self.',
        description: 'Focus on long-term improvements for a stress-free life.',
      },
      {
        heading: 'Build fulfilling relationships.',
        description:
          'Improve communication skills and empathy to connect better with others.',
      },
    ],
    chart: {
      start: 'Your Insecurity Level',
      end: 'Your Target Level',
    },
    newspaper:
      'Transform your life and reach your goals with a customized plan to eliminate self-doubt and improve your relationships, social life, and career.\n \nTake the first step now towards becoming your best self.',
    scienceBacked: [
      {
        image: '/static/icons/bed.png',
        heading: 'Improved sleep quality',
        description:
          'Low self-confidence can seriously disrupt your sleep and significantly reduce sleep quality by at least 36%.',
      },
      {
        image: '/static/icons/relieved.png',
        heading: 'Boosted positive feelings',
        description:
          'Embrace your true self and experience the joy of increased happiness with self-acceptance.',
      },
      {
        image: '/static/icons/cash.png',
        heading: 'Earn more money',
        description:
          'Taking care of mental health leads to increased productivity, better decision making, and improved relationships, leading to greater success and earning potential.',
      },
      {
        image: '/static/icons/brain.png',
        heading: 'Increased cognitive ability',
        description:
          'Enhancing self-acceptance has been shown to increase cognitive processing power, according to a study conducted by scientists in Utah.',
      },
    ],
  },
};

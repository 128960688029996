export const procrastination = {
  Name: 'procrastination',
  Landing: {
    heading: 'Say goodbye to procrastination & live your life to the fullest',
    description:
      'Complete a quick quiz to get your personal anti-procrastination plan and tap into your full potential.',
  },
  Quiz: {
    quizFlow: [
      {
        type: 'landing',
      },
      {
        image: '/static/icons/1.svg',
        question: 'What is your age?',
        questionCode: 'age',
        subtitle:
          'This information will help us calculate your personal procrastination score.',
        answers: [
          '18 - 24 years',
          '25 - 34 years',
          '35 - 44 years',
          '45 - 54 years',
          '55+ years',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/2.svg',
        question: 'Do you often leave tasks to the last minute?',
        questionCode: 'task_delay_frequency',
        answers: ['All the time', 'Frequently', 'Rarely', 'Never'],
        type: 'question',
      },
      {
        image: '/static/icons/3.svg',
        questionCode: 'agree_statement_1',
        question: 'Do you agree with the following statement?',
        subtitle: '"I find it difficult to make decisions quickly."',
        answers: ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'],
        type: 'question',
      },
      {
        image: '/static/icons/4.svg',
        questionCode: 'discomfort_in_task_completion',
        question: 'How frequently do you find yourself getting distracted?',
        answers: ['All the time', 'Frequently', 'Rarely', 'Never'],
        type: 'question',
      },
      {
        image: '/static/icons/5.svg',
        questionCode: 'do_it_later',
        question:
          "When I need to do something I don't want to, I convince myself that I'll do it later.",
        answers: ['Almost never', 'Sometimes', 'Almost always'],
        type: 'question',
      },
      {
        image: '/static/icons/6.svg',
        question: 'Why do you think you put things off?',
        questionCode: 'task_delay_reasons',
        subtitle: 'Select as many or few options as you like.',
        answers: [
          'I feel like I have too much on my plate',
          'I find it difficult to manage my time or plan ahead',
          'I am afraid to fail (or succeed)',
          'I am bored or uninterested',
          'I find it difficult to stay motivated',
          'There are times when I am unsure of myself and lack confidence to complete a specific task or project',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/7.svg',
        question: "How long do you think you've been procrastinating?",
        questionCode: 'task_delay_duration',
        answers: [
          '0-12 months',
          '12-24 months',
          '24-36 months',
          'More than 3 years',
          'As long as I can remember',
          "Don't know",
        ],
        type: 'question',
      },
      {
        image: '/static/icons/8.svg',
        question: 'Do you agree with the following statement?',
        questionCode: 'ill_finish_later',
        subtitle: '"I tell myself I\'ll finish this later, but I never do."',
        answers: ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'],
        type: 'question',
      },
      {
        image: '/static/icons/9.svg',
        question: 'What do you feel happens when you procrastinate?',
        questionCode: 'what_happens_procrastination',
        subtitle: 'Select as many or few options as you like.',
        answers: [
          'I lose my precious time',
          'I let down my loved ones',
          'I miss out on good opportunities',
          'I don’t meet my goals',
          'I am ruining my career by not meeting targets',
          'I make poor decisions due to time running out',
          'My self-esteem takes a hit',
          'I feel anxious or depressed',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        type: 'info',
        description:
          "“You’re not alone - procrastination often causes stress, guilt, or anxiety related to the loss of productivity or missed opportunities, which can promote even more negative thinking.\n\n If you ever tried to overcome procrastination and failed, it's probably because you didn't know what your triggers are.\n\nTo boost your productivity once and for all, you need to understand your triggers first and then follow a system that works specifically for you!\"\n\nScience and Research Team at Minderse.",
        button: 'Continue',
      },
      {
        image: '/static/icons/1.svg',
        question:
          'I come up with reasonable-sounding excuses to explain my lack of productivity',
        questionCode: 'excuses',
        answers: ['Rarely', 'Sometimes', 'Often'],
        type: 'question',
      },
      {
        image: '/static/icons/2.svg',
        question: 'How often do you feel overwhelmed or stressed?',
        questionCode: 'excuses',
        answers: ['Rarely', 'Sometimes', 'Often'],
        type: 'question',
      },
      {
        image: '/static/icons/3.svg',
        question: 'Why do you want to be procrastination-free?',
        questionCode: 'why_stop_procrastinating',
        subtitle: 'Select as many or few options as you like.',
        answers: [
          'To be free of anxiety and depression',
          'To feel better and have more energy',
          'To be more like someone I admire',
          'To achieve my personal goals',
          'To be successful at work',
          'To have a higher self-esteem',
          'To have a better relationship',
          "To have a body I'm proud of",
          'To have a clutter-free home',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/icons/brenda.png',
        heading: 'TAKE CONTROL OF YOUR PRODUCTIVITY!',
        description:
          '"I used to struggle with procrastination and felt like I was constantly behind. Minderse helped me identify my procrastination habits and gave me the tools to improve my productivity. Now, I feel more in control of my time and able to tackle tasks with confidence.\r\n\r\nIf you’re looking to break the cycle of procrastination, I highly recommend trying Minderse."',
        button: 'Continue',
      },
      {
        image: '/static/icons/4.svg',
        question:
          'Your good friend urges you to quit your job and help him out with his new exciting business project. What do you do?',
        questionCode: 'excuses',
        answers: [
          "I'll keep my day job while working on the new project on the side.",
          "I'm not interested, I'll keep my current job.",
          "You immediately quit your current job, even though you don't have anything else lined up.",
          "I'll save up some money first so I have a cushion in case things don't work out.",
        ],
        type: 'question',
      },
      {
        image: '/static/icons/5.svg',
        question: 'I could stop procrastination if I could have more...',
        questionCode: 'what_you_need_more',
        subtitle:
          'This will help us better understand your goals. Choose as many as you like',
        answers: ['Focus', 'Peace & Quiet', 'Perseverance', 'Energy', 'Willpower'],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/6.svg',
        question: 'Which of these do you tend to procrastinate the most on?',
        questionCode: 'where_you_procrastinate',
        subtitle: 'Choose all that apply to you',
        answers: [
          'Drinking more water',
          'Exercise & fitness',
          'Reading',
          'Eating healthy',
          'Working or Studying',
          'Meditation',
          'Regular Sleep',
          'Yoga',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        question: 'Do you have any habits that you would like to stop or break?',
        questionCode: 'want_to_stop',
        subtitle: 'Select as many options as you wish.',
        answers: [
          {
            image: '/static/icons/alcohol.png',
            text: 'Drinking',
          },
          {
            image: '/static/icons/social-media.png',
            text: 'Social media',
          },
          {
            image: '/static/icons/sad.png',
            text: 'Insecurity',
          },
          {
            image: '/static/icons/hamburger.png',
            text: 'Fast food',
          },
          {
            image: '/static/icons/clock.png',
            text: 'Losing sleep',
          },
          {
            image: '/static/icons/biting.png',
            text: 'Nail-biting',
          },
          {
            image: '/static/icons/television.png',
            text: 'Binge watching',
          },
          {
            image: '/static/icons/smoking.png',
            text: 'Smoking',
          },
        ],
        isImageType: true,
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/7.svg',
        question: "Tell us what goals you'd like to achieve with the help of your plan.",
        questionCode: 'goals_to_achieve',
        subtitle: 'Choose all that apply to you',
        answers: [
          'Productivity',
          'Gain more Self-Discipline',
          'Have more Willpower',
          'Get rid of bad habits',
          'Boost motivation',
          'Overcome Stress, anxiety, and depression',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/8.svg',
        question:
          'What is the amount of time you are willing to commit to self-improvement on a daily basis?',
        questionCode: 'ammount_of_daily_time',
        answers: ['5 min / day', '10 min / day', '15 min / day', '20+ min / day'],
        type: 'question',
      },
    ],
  },
  GetStarted: {
    description:
      'We have developed a plan to help you overcome procrastination and achieve your goals.',
    button: 'Continue',
  },
  Summary: {
    heading: 'Stop procrastinating, accomplish more, and boost your confidence today',
    resultsTitle: 'Procrastination score',
    suggestedPlan: 'anti-procrastination',
    issue: 'procrastination',
    summary: [
      {
        heading: 'Lack of Internal Motivation',
        description:
          'It appears that you primarily rely on extrinsic and short-term motivation.',
      },
      {
        heading: 'Low Frustration Tolerance',
        description:
          'It seems that you often add negative thoughts to already frustrating situations.',
      },
      {
        heading: 'Perfectionism',
        description:
          'It appears that you might be a perfectionist and are never quite satisfied with your work.',
      },
      {
        heading: 'Avoidance of Discomfort',
        description:
          'It seems that you tend to focus on and amplify any discomfort you feel.',
      },
    ],
  },
  Purchase: {
    suggestedPlan: 'Anti-Procrastination',
    promise: 'boost your productivity',
    subtitle:
      'Overcome procrastination and feel more productive, form healthy habits, reduce anxious thoughts',
    benefits: [
      {
        heading: 'Maximize your productivity.',
        description:
          'Achieve your objectives faster and more efficiently by utilizing your strengths.',
      },
      {
        heading: 'Boost your confidence.',
        description:
          'Develop a positive self-image and the skills to tackle challenges with assurance.',
      },
      {
        heading: 'Strengthen your resolve.',
        description:
          'Create effective plans and self-discipline strategies to stay on track with your goals.',
      },
      {
        heading: 'Decrease stress and anxiety.',
        description:
          'Discover ways to handle stress and manage anxiety for a calmer mind.',
      },
      {
        heading: 'Transform into your best self.',
        description:
          'Focus on long-term personal development for a more peaceful and fulfilling life.',
      },
      {
        heading: 'Develop meaningful relationships.',
        description:
          'Improve your communication and empathy skills to create deeper connections with others.',
      },
    ],
    chart: {
      start: 'Your Procrastination Level',
      end: 'Your Target Level',
    },
    newspaper:
      'Transform your life with our proven plan to beat procrastination and boost willpower.\r\n\r\nTake the first step towards becoming your best self today.',
    scienceBacked: [
      {
        image: '/static/icons/bed.png',
        heading: 'Improved sleep quality',
        description:
          'Low self-confidence can seriously disrupt your sleep and significantly reduce sleep quality by at least 36%.',
      },
      {
        image: '/static/icons/relieved.png',
        heading: 'Boosted positive feelings',
        description:
          'Embrace your true self and experience the joy of increased happiness with self-acceptance.',
      },
      {
        image: '/static/icons/cash.png',
        heading: 'Earn more money',
        description:
          'Taking care of mental health leads to increased productivity, better decision making, and improved relationships, leading to greater success and earning potential.',
      },
      {
        image: '/static/icons/brain.png',
        heading: 'Increased cognitive ability',
        description:
          'Enhancing self-acceptance has been shown to increase cognitive processing power, according to a study conducted by scientists in Utah.',
      },
    ],
  },
};

import { API as AccountAPI } from 'account-types';

export const EmailRegexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export enum EventTypes {
  purchase = 'purchase',
  answerSet = 'answer-set',
  orderFormOpened = 'order-form-opened',
}

export enum PaymentMethods {
  paypal = 'paypal',
  stripe = 'stripe',
  apple_google = 'apple_google',
  paddle = 'paddle',
}

export enum SubscriptionTypes {
  trial = 'trial',
  subscription = 'subscription',
}

export const ApiEndpoints = {
  purchasePlan: AccountAPI.Stripe.PurchasePlan.endpoint.URL,
  purchaseCart: AccountAPI.Stripe.PurchaseCart.endpoint.URL,
  getSetupIntent: AccountAPI.Stripe.GetSetupIntent.endpoint.URL,

  trackEvent: AccountAPI.Events.Track.endpoint.URL,
  trackFBEvent: AccountAPI.Events.TrackFacebook.endpoint.URL,
};

export const TrackEventTypes = {
  emailLeft: 'email-left',
  purchasePageViewed: 'purchase-page-viewed',
  summaryPageViewed: 'summary-page-viewed',
  downsellPageViewed: 'downsell-page-viewed',
  planSelected: 'plan-selected',
  orderFormOpened: 'order-form-opened',
  orderPlaced: 'order-placed',
  emailOfferPageViewed: 'email-offer-page-viewed',
  upsellOrderTriggered: 'upsell-order-triggered',
  upsellOrderPlaced: 'upsell-order-placed',
  addressLeft: 'address-left',
  registerSubmitted: 'register-submitted',
};

const paymentMethods = {
  trial: {
    url: '/purchase-trial',
    name: 'trial',
  },
  subscription: {
    url: '/purchase-plan',
    name: 'subscription',
  },
};

export const PaymentTypes = {
  trial: paymentMethods.trial,
  subscription: paymentMethods.subscription,
  default: paymentMethods.subscription,
};

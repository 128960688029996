export const childhoodTrauma = {
  Name: 'ctr',
  Landing: {
    heading: 'Heal childhood trauma, trust wounds and subconscious mind',
    description:
      'Take a short test to learn about your inner child’s needs, purpose in life and coping mechanisms. Receive a plan & grounding tools to manage self-sabotage, silence inner critic and negativity.',
  },

  // "productivityRate":"7",
  // "troublesFinishingThings":"Rarely",
  // "procrastinate":"Rarely",
  // "moodRate":"7",
  // "regretThinkingAboutPast":"Rarely",
  // "worryThinkingAboutFuture":"Rarely",
  // "firstQuestion":"Don't Know",
  // "difficultyRememberingNames":"Often",
  // "forgetThings":"Rarely"}
  Quiz: {
    quizFlow: [
      {
        image: '/static/icons/1.svg',
        question: 'What is your age?',
        questionCode: 'age',
        subtitle: 'This information will be used to calculate your self-esteem score.',
        answers: [
          '18 - 24 years',
          '25 - 34 years',
          '35 - 44 years',
          '45 - 54 years',
          '55+ years',
        ],
        type: 'question',
      },
      {
        image: '/static/illusions/1.png',
        question: 'What did you see first?',
        questionCode: 'illusion1_first_seen',
        answers: ['2 people', 'A kangaroo'],
        type: 'question',
      },
      {
        image: '/static/illusions/2.png',
        question: 'What did you see first?',
        questionCode: 'illusion2_first_seen',
        answers: ['A city', 'An umbrella'],
        type: 'question',
      },
      {
        image: '/static/illusions/3.png',
        question: 'What did you see first?',
        questionCode: 'illusion3_first_seen',
        answers: ['2 cats', 'A dog'],
        type: 'question',
      },
      {
        image: '/static/illusions/4.png',
        question: 'What did you see first?',
        questionCode: 'illusion4_first_seen',
        answers: ['A shark', 'A leg'],
        type: 'question',
      },
      {
        image: '/static/illusions/5.png',
        question: 'What did you see first?',
        questionCode: 'illusion5_first_seen',
        answers: ['An apple', 'Two faces'],
        type: 'question',
      },
      {
        image: '/static/icons/9.svg',
        questionCode: 'trauma_effects_duration',
        question: 'For how long have you noticed the effects of traumas on your life?',
        subtitle: 'This will determine how strong your neural connections are.',
        answers: [
          '0-12 months',
          '12-36 months',
          'As long as I can remember',
          "I don't struggle with low self-esteem",
        ],
        type: 'question',
      },
      {
        image: '/static/icons/3.svg',
        questionCode: 'insecurity_in_social_situations',
        question: 'I will never amount to anything or anyone significant.',
        subtitle: 'Do you agree with the following statement?',
        answers: ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'],
        type: 'question',
      },
      {
        type: 'info',
        description:
          '“You’re not alone – low self-esteem often causes stress, guilt, or anxiety related to the loss of self-worth or opportunities, which can promote even more negative thinking.\n\n If you ever tried to overcome low self-esteem and failed, it’s probably because you didn’t know what your type is.\n\nTo boost your self-love once and for all, you need to understand your type first and then follow a system that works specifically for you!”\n\nScience and Research Team\nat Minderse',
        button: 'Continue',
      },
      {
        image: '/static/icons/7.svg',
        question: 'What do you think are the reasons behind your low self-esteem?',
        questionCode: 'reasons_low_self_esteem',
        subtitle: 'Check all that might apply to you:',
        answers: [
          'Toxic relationships',
          'Childhood trauma',
          'Gender',
          'Body type',
          'Social media',
          'Other',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/5.svg',
        question: "People will only like me if I'm good-looking and successful",
        questionCode: 'criticism_response',
        subtitle: 'Do you agree with the following statement?',
        answers: ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'],
        type: 'question',
      },
      {
        image: '/static/icons/6.svg',
        question: 'I am happy with the way I look',
        questionCode: 'happiness_with_appearance',
        subtitle: 'Do you agree with the following statement?',
        answers: ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'],
        type: 'question',
      },
      {
        image: '/static/icons/7.svg',
        question: 'Why are you embarking on this journey to heal your trauma?',
        questionCode: 'reasons_for_healing_journey',
        subtitle: 'Check all that might apply to you:',
        answers: [
          'To feel better about myself',
          'To stop doubting myself',
          'To improve my health',
          'To get rid of anxiety and depression',
          'To develop deep and positive relationships',
          'To stop beating myself up for small mistakes',
          'Other',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/icons/brenda.png',
        heading: 'NEXT LEVEL SELF-WORTH!',
        description:
          '"I used to feel inferior and powerless, but Minderse gave me the tools to change that. My self-confidence has greatly improved, and I now feel comfortable in myself and capable of achieving my goals.\r\n\r\nIf you\'re struggling with low self-worth, I highly recommend giving Minderse a try."',
        button: 'Continue',
      },

      {
        image: '/static/icons/1.svg',
        question: 'What are you missing due to traumas?',
        questionCode: 'missing_due_to_traumas',
        subtitle: 'Choose as many as you like',
        answers: [
          'Fulfilling my true potential',
          'Feeling that I’m good enough',
          'Letting go of past failures',
          'Looking better',
          'Accepting and loving myself more',
          'Handling rejection better',
          'Other',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/8.svg',
        question:
          'Which areas of your life would improve the most if you healed your traumas?',
        questionCode: 'improvement_areas',
        answers: [
          'Mental health',
          'Relationships',
          'Career and finance',
          'Social life',
          'Other',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        question: 'Are there any habits you’d like to quit?',
        questionCode: 'quit_habits',
        subtitle: 'Select as many options as you wish.',
        answers: [
          {
            image: '/static/icons/alcohol.png',
            text: 'Drinking',
          },
          {
            image: '/static/icons/social-media.png',
            text: 'Social media',
          },
          {
            image: '/static/icons/sad.png',
            text: 'Procrastination',
          },
          {
            image: '/static/icons/hamburger.png',
            text: 'Fast food',
          },
          {
            image: '/static/icons/clock.png',
            text: 'Losing sleep',
          },
          {
            image: '/static/icons/biting.png',
            text: 'Nail-biting',
          },
          {
            image: '/static/icons/doubt.png',
            text: 'Doubting yourself',
          },
          {
            image: '/static/icons/none.png',
            text: 'None/Other',
          },
        ],
        isImageType: true,
        isMultiSelect: true,
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/icons/stats.png',
        heading: 'Ready to see your plan?',
        description:
          "Minderse's behavior change plans are grounded in the latest behavioral science research and expertly crafted by psychologists, coaches, and behavioral specialists.\r\n\r\nOur personalized approach targets the underlying causes of behavior, enabling long-term, sustainable habit change.",
        button: 'Continue',
      },
      {
        image: '/static/icons/2.svg',
        question: "Tell us what you'd be interested in in your plan.",
        subtitle: 'Select as many options as you wish.',
        isMultiSelect: true,
        questionCode: 'what_interests',
        answers: [
          'Achieving emotional freedom',
          'Building deep and meaningful relationships',
          'Increasing confidence',
          'Unlocking full potential',
          'Finding true inner peace',
          'Getting rid of trust issues',
          'Other',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/1.svg',
        question:
          'What is the amount of time you are willing to commit to self-improvement on a daily basis?',
        questionCode: 'amount_of_daily_time',
        answers: ['5 min / day', '10 min / day', '15 min / day', '20+ min / day'],
        type: 'question',
      },
      {
        type: 'email',
      },
    ],
  },
  GetStarted: {
    description:
      'We have created a personalized plan specifically for you to improve your self-esteem and achieve your goals.',
    button: 'Continue',
  },
  Summary: {
    heading: 'Discover your personal growth plan',
    resultsTitle: 'Inner critic score',
    suggestedPlan: 'Trauma Recovery',
    issue: 'low self-esteem',
    summary: [
      {
        heading: 'Rejection anxiety',
        description:
          'You may be limiting yourself and holding yourself back because of a fear of being rejected.',
      },
      {
        heading: 'Overwhelming self-doubt',
        description:
          'Based on your scores, it seems that you have low self-confidence in yourself and your abilities.',
      },
      {
        heading: 'Perfectionism',
        description:
          'It appears that you might be a perfectionist and are never quite satisfied with your work.',
      },
      {
        heading: 'Powerful inner critic',
        description:
          'You frequently criticize yourself harshly for any deviations from your plans.',
      },
    ],
  },
  Purchase: {
    suggestedPlan: 'Self-Improvement',
    promise: 'boost self-esteem and overcome trauma symptoms',
    subtitle:
      'Feel more confident and secure, form healthy bonds, reduce anxious thoughts',
    benefits: [
      {
        heading: 'Build confidence.',
        description:
          'Develop a positive self-image and the skills to overcome obstacles with confidence.',
      },
      {
        heading: 'Boost your efficiency.',
        description: 'Achieve goals faster and easier by leveraging your strengths.',
      },
      {
        heading: 'Strengthen determination.',
        description: 'Develop effective planning strategies and self-control mechanisms.',
      },
      {
        heading: 'Reduce stress and anxiety.',
        description: 'Learn to identify and manage stressors and overcome anxiety.',
      },
      {
        heading: 'Transform into your best self.',
        description: 'Focus on long-term improvements for a stress-free life.',
      },
      {
        heading: 'Build fulfilling relationships.',
        description:
          'Improve communication skills and empathy to connect better with others.',
      },
    ],
    chart: {
      start: 'Your Insecurity Level',
      end: 'Your Target Level',
    },
    newspaper:
      'Transform your life and reach your goals with a customized plan to eliminate self-doubt and improve your relationships, social life, and career.\n \nTake the first step now towards becoming your best self.',
    scienceBacked: [
      {
        image: '/static/icons/bed.png',
        heading: 'Improved sleep quality',
        description:
          'Low self-confidence can seriously disrupt your sleep and significantly reduce sleep quality by at least 36%.',
      },
      {
        image: '/static/icons/relieved.png',
        heading: 'Boosted positive feelings',
        description:
          'Embrace your true self and experience the joy of increased happiness with self-acceptance.',
      },
      {
        image: '/static/icons/cash.png',
        heading: 'Earn more money',
        description:
          'Taking care of mental health leads to increased productivity, better decision making, and improved relationships, leading to greater success and earning potential.',
      },
      {
        image: '/static/icons/brain.png',
        heading: 'Increased cognitive ability',
        description:
          'Enhancing self-acceptance has been shown to increase cognitive processing power, according to a study conducted by scientists in Utah.',
      },
    ],
  },
};

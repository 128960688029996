export const chakras = {
  Name: 'chakras',
  Landing: {
    heading: 'Activate 7 chakras with tested techniques',
    description:
      'Sleep well, reduce stress, improve relationships, boost creativity and feel happiness.',
  },
  Quiz: {
    quizFlow: [
      {
        image: '/static/icons/1.svg',
        question: 'What is your age?',
        questionCode: 'age',
        subtitle:
          'This information will help us personalize a plan to unlock your chakras.',
        answers: [
          '18 - 24 years',
          '25 - 34 years',
          '35 - 44 years',
          '45 - 54 years',
          '55+ years',
        ],
        type: 'question',
      },
      {
        image: '/static/illusions/1.png',
        question: 'What did you see first?',
        questionCode: 'illusion1_first_seen',
        answers: ['2 people', 'A kangaroo'],
        type: 'question',
      },
      {
        image: '/static/illusions/2.png',
        question: 'What did you see first?',
        questionCode: 'illusion2_first_seen',
        answers: ['A city', 'An umbrella'],
        type: 'question',
      },
      {
        image: '/static/illusions/3.png',
        question: 'What did you see first?',
        questionCode: 'illusion3_first_seen',
        answers: ['2 cats', 'A dog'],
        type: 'question',
      },
      {
        image: '/static/illusions/4.png',
        question: 'What did you see first?',
        questionCode: 'illusion4_first_seen',
        answers: ['A shark', 'A leg'],
        type: 'question',
      },
      {
        image: '/static/illusions/5.png',
        question: 'What did you see first?',
        questionCode: 'illusion5_first_seen',
        answers: ['An apple', 'Two faces'],
        type: 'question',
      },
      {
        image: '/static/icons/3.svg',
        questionCode: 'areas_to_improve',
        question: 'What areas would you like to improve?',
        answers: ['Energy', 'Focus', 'Motivation', 'Memory', 'Mood', 'Productivity'],
        type: 'question',
        isMultiSelect: true,
      },
      {
        image: '/static/icons/5.svg',
        question:
          'How many cups of coffee or caffeinated beverages do you drink in a typical day?',
        questionCode: 'cups_of_coffee',
        answers: ['0', '1', '2', '3+'],
        type: 'question',
      },
      {
        type: 'info',
        heading: 'What can Minderse do for you?',
        description:
          "Chakras are the energy centers in our bodies, and when they're blocked, we can experience physical, emotional, and spiritual issues. By taking this quiz and sharing your answers with us, we will create a personalized plan to help you unblock your chakras and bring balance to your life.\r\n\r\nLet's work together to unlock your full potential.",
        button: 'Continue',
      },
      {
        image: '/static/icons/6.svg',
        question: 'How do you feel when you consume caffeine?',
        questionCode: 'feeling_about_caffeine',
        subtitle: 'Select as many or few options as you like.',
        answers: ['Energized', 'Focused', 'Nervous', 'No effect'],
        type: 'question',
      },
      {
        image: '/static/icons/7.svg',
        question: 'How often do you exercise?',
        questionCode: 'how_often_exercise',
        answers: ['Never', '1-2 times a week', '3-4 times a week', '4+ times a week'],
        type: 'question',
      },
      {
        image: '/static/icons/8.svg',
        question: 'How many hours do you sleep in a typical night?',
        questionCode: 'hours_of_sleep',
        answers: ['Varies greatly', '0-4 hrs', '4-6 hrs', '6-8 hrs', '8+ hours'],
        type: 'question',
      },
      {
        image: '/static/icons/4.svg',
        question: 'Do you feel rested when you wake up?',
        questionCode: 'feel_rested',
        answers: ['Never', 'Rarely', 'Sometimes', 'Often', 'Always'],
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/icons/brenda.png',
        heading: 'Unlock your chakras just like I did',
        description:
          '"I used to struggle with anxiety and disconnection, but Minderse gave me the tools to change that. My chakras feel unblocked, and I now feel grounded, balanced, and in tune with my emotions.\r\n\r\nIf you\'re looking to bring more balance into your life, I highly recommend giving Minderse a try."',
        button: 'Continue',
      },
      {
        image: '/static/icons/9.svg',
        question: 'How would you rate your energy in a typical day?',
        questionCode: 'energy_level',
        answers: [
          'Exhausted',
          'Drained',
          'Tired but functional',
          'Refreshed',
          'Energized',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/1.svg',
        question: 'Do you have trouble getting out of bed in the morning?',
        questionCode: 'troubles_getting_out_of_bed',
        answers: ['Never', 'Rarely', 'Sometimes', 'Often'],

        type: 'question',
      },
      {
        image: '/static/icons/2.svg',
        question: 'How often do you check social media?',
        questionCode: 'how_often_check_social_media',
        answers: ['Never', 'Few times a day', 'Often', 'Very often'],
        type: 'question',
      },
      {
        image: '/static/icons/3.svg',
        question: 'Do you experience anxiety in social situations?',
        questionCode: 'anxiety_in_social_situations',
        answers: ['Never', 'Rarely', 'Sometimes', 'Often'],
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/icons/stats.png',
        heading: 'Ready to see your plan?',
        description:
          "Minderse's Chakra unlocking program is grounded in ancient Eastern wisdom and expertly crafted by experienced yogis, meditation teachers, and energy healers.\r\n\r\nOur personalized approach targets the blocked energy centers of your body, enabling you to achieve optimal physical, emotional, and spiritual well-being.",
        button: 'Continue',
      },
      {
        image: '/static/icons/6.svg',
        question:
          'What is the amount of time you are willing to commit to Chakra unlocking practice on a daily basis?',
        questionCode: 'amount_of_daily_time',
        answers: ['5 min / day', '10 min / day', '15 min / day', '20+ min / day'],
        type: 'question',
      },
      {
        type: 'email',
      },
    ],
  },
  GetStarted: {
    description:
      'We have created a personalized plan specifically for you to unlock your chakras and achieve your spiritual goals.',
    button: 'Continue',
  },
  Summary: {
    heading: 'Unlock your chakras for a healthier mind and body!',
    resultsTitle: 'Chakra imbalance score',
    suggestedPlan: 'chakra healing',
    issue: 'blocked chakras',
    summary: [
      {
        heading: 'Root chakra blockage',
        description:
          'You may be feeling disconnected and lacking a sense of stability and security in your life.',
      },
      {
        heading: 'Sacral chakra imbalance',
        description:
          'Based on your scores, it seems that you are struggling with creativity, pleasure, and emotional balance.',
      },
      {
        heading: 'Solar plexus chakra blockage',
        description:
          'It appears that you might be experiencing low self-esteem, self-doubt, and feelings of powerlessness.',
      },
      {
        heading: 'Heart chakra imbalance',
        description:
          'Your scores suggest that you may be struggling with self-love, compassion, and healthy relationships.',
      },
      {
        heading: 'Throat chakra blockage',
        description:
          'You may be having trouble expressing yourself and communicating your thoughts and feelings effectively.',
      },
    ],
  },
  Purchase: {
    suggestedPlan: 'Chakras Unlocking',
    promise: 'harmony and balance',
    subtitle:
      'Unlock your chakras, achieve harmony and balance, and cultivate healthy connections',
    benefits: [
      {
        heading: 'Balance Your Energy',
        description:
          'Unlock the power of chakra balancing to create inner peace and harmony, leading to a more fulfilling and balanced life.',
      },
      {
        heading: 'Overcome Blocks',
        description:
          'Remove blockages and open your chakras to their full potential, allowing you to tap into your inner strength and creativity.',
      },
      {
        heading: 'Embrace Your Authentic Self',
        description:
          'Discover your true self and become empowered and confident in all areas of your life.',
      },
      {
        heading: 'Expand Your Intuition',
        description:
          'Deepen your intuitive abilities and develop a stronger connection with your inner wisdom.',
      },
      {
        heading: 'Nurture Emotional Resilience',
        description:
          'Cultivate emotional resilience to cope with trauma and stress, fostering a greater sense of inner strength and well-being.',
      },
      {
        heading: 'Restore Inner Healing: Empowered and Confident',
        description:
          "Activate your body's natural healing abilities, promoting physical, emotional, and spiritual well-being.",
      },
    ],
    chart: {
      start: 'Your Chakra Imbalance Level',
      end: 'Your Balanced Chakra Level',
    },
    newspaper:
      'Transform your life and unlock your full potential with a customized plan to balance your chakras and improve your physical, emotional, and spiritual well-being.\n \nTake the first step now towards achieving harmony and balance in your life.',
    scienceBacked: [
      {
        image: '/static/icons/bed.png',
        heading: 'Improved Sleep',
        description:
          'Balancing your chakras can lead to improved sleep quality and a more restful night’s sleep.',
      },
      {
        image: '/static/icons/relieved.png',
        heading: 'Reduced Stress',
        description:
          'Chakra balancing has been shown to reduce stress and anxiety, leading to improved mental and emotional well-being.',
      },
      {
        image: '/static/icons/cash.png',
        heading: 'Increased Productivity',
        description:
          'Unlocking your chakras can increase focus and productivity, leading to greater success and earning potential.',
      },
      {
        image: '/static/icons/brain.png',
        heading: 'Enhanced Intuition',
        description:
          'Balancing your chakras can enhance creativity and intuition, leading to a more fulfilling and satisfying life.',
      },
    ],
  },
};

export const adhd = {
  Name: 'adhd',
  Landing: {
    heading: 'Get a personalized ADHD management plan',
    description: 'Improve focus, manage overwhelm and create your ultimate routine.',
  },
  Quiz: {
    quizFlow: [
      {
        image: '/static/icons/1.svg',
        question: 'What is your age?',
        questionCode: 'age',
        subtitle:
          'This information will help us provide you with more accurate insights about your ADHD.',
        answers: [
          '18 - 24 years',
          '25 - 34 years',
          '35 - 44 years',
          '45 - 54 years',
          '55+ years',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/2.svg',
        question: 'Were you diagnosed with ADHD?',
        questionCode: 'diagnosed_with_adhd',
        answers: [
          'Diagnosed by a doctor',
          'Self-diagnosed',
          'I suspect I might have ADHD',
          'Other',
        ],
        type: 'question',
      },

      {
        image: '/static/icons/3.svg',
        question: 'Have you ever struggled with any of the following?',
        questionCode: 'struggle_with',
        subtitle: 'Select as many or few options as you like.',
        answers: [
          'Depression',
          'Anxiety',
          'Burnout',
          'OCD',
          'BPD',
          'Struggles with sleep',
          'Other',
        ],
        isMultiSelect: true,
        type: 'question',
      },

      {
        image: '/static/icons/4.svg',
        question: 'Do you regularly take medication to manage your health condition(s)?',
        questionCode: 'do_you_take_medications',
        answers: [
          'Yes, I take medication regularly',
          'Yes, but I would like to stop',
          "No, and I'm not planning to",
          'No, but I want to',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/5.svg',
        question: 'Which of the scenarios below can you relate to?',
        subtitle: 'Choose all that apply to you.',
        questionCode: 'relatable_scenarios',
        answers: [
          'I struggle doing the things I need to do',
          'I feel anxious and depressed',
          'Life feels too chaotic',
          'I feel burned out and exhausted',
          "I don't want to be dependent on medication",
          'I have low self-esteem',
          'None of the above',
        ],
        type: 'question',
        isMultiSelect: true,
      },

      {
        image: '/static/icons/6.svg',
        question: 'Can you relate to any of these symptoms?',
        subtitle: 'Choose all that apply to you.',
        questionCode: 'relatable_symptoms',
        answers: [
          'Mood swings',
          'Lack of motivation',
          'Low self-esteem',
          'Racing thoughts',
          'Difficulty in focusing',
          'Chronic lateness',
          'Fidgeting or restlessness',
          'Forgetting commitments',
          'Other',
        ],
        type: 'question',
        isMultiSelect: true,
      },
      {
        type: 'info',
        description:
          '“If you have ADHD, you’re not alone. ADHD affects millions of people worldwide, and can impact daily life in many ways.\n\nTo manage your ADHD effectively, you need to understand your symptoms and follow a system that works specifically for you!”\n\nScience and Research Team\nat Minderse',
        button: 'Continue',
      },
      {
        image: '/static/icons/7.svg',
        question: 'How easily distracted are you?',
        subtitle: '',
        questionCode: 'distractibility',
        answers: [
          'Easily distracted',
          'Sometimes lose focus',
          'Rarely lose focus',
          'Always laser-focused',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/8.svg',
        question: 'What affects your productivity the most?',
        subtitle: 'Choose all that apply to you.',
        questionCode: 'productivity_factors',
        answers: [
          'Stress at work',
          'Problems at home',
          'Struggles with relationships',
          'Emotional trauma',
          'Fear of success',
          'Perfectionism',
          'Fear of failure',
        ],
        type: 'question',
        isMultiSelect: true,
      },

      {
        image: '/static/icons/9.svg',
        question: 'How do you feel about the following statement?',
        subtitle:
          '“I tend to get overwhelmed when I have to do complex tasks which require a lot of organization.“',
        questionCode: 'complex_task_overwhelm',
        answers: ['Strongly agree', 'Somewhat agree', 'Disagree', 'Strongly disagree'],
        type: 'question',
      },

      {
        question: 'Which of the following habits or activities do you struggle with?',
        questionCode: 'adhd_influenced',
        subtitle:
          'Please select all of the options that apply to you - this will give us insight into your personal habits.',
        answers: [
          {
            image: '/static/icons/relationship.png',
            text: 'Relationships',
          },
          {
            image: '/static/icons/barbell.png',
            text: 'Exercising',
          },
          {
            image: '/static/icons/apple.png',
            text: 'Balanced diet',
          },
          {
            image: '/static/icons/laptop.png',
            text: 'Staying focused',
          },
          {
            image: '/static/icons/sleep.png',
            text: 'Getting enough sleep',
          },
          {
            image: '/static/icons/speaking.png',
            text: 'Communicating',
          },
          {
            image: '/static/icons/dart.png',
            text: 'Achieving goals',
          },
          {
            image: '/static/icons/none.png',
            text: 'None/Other',
          },
        ],
        isImageType: true,
        isMultiSelect: true,
        type: 'question',
      },

      {
        type: 'info',
        image: '/static/icons/brenda.png',
        heading: 'NEXT LEVEL PRODUCTIVITY!',
        description:
          '"Thanks to Minderse I now have a clear structure to achieve my goals. I feel much more productive and my self-esteem has improved drastically. I feel like a completely new person."',
        button: 'Continue',
      },
      {
        image: '/static/icons/3.svg',
        question: 'What are your main goals?',
        subtitle:
          'Choose all that apply to you - this will help us understand your personal goals.',
        questionCode: 'main_goals',
        answers: [
          'Be more productive',
          'Improve my focus',
          'Reduce overwhelm',
          'Get better sleep',
          'Reduce stress',
          'Other',
        ],
        type: 'question',
        isMultiSelect: true,
      },
      {
        image: '/static/icons/1.svg',
        question: 'Any other areas you would like to improve?',
        questionCode: 'areas_to_improve',
        answers: [
          'Mood',
          'Build a routine',
          'Strengthen willpower',
          'Eliminate procrastination',
          'Concentration',
          'Overall mental health',
          'Other',
        ],
        type: 'question',
        isMultiSelect: true,
      },
      {
        image: '/static/icons/2.svg',
        question: 'Do you agree with the following statement?',
        subtitle: '“I believe I can achieve more in my life.“',
        questionCode: 'belief_in_achievement',
        answers: ['Strongly agree', 'Somewhat agree', 'Disagree', 'Strongly disagree'],
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/icons/stats.png',
        heading: 'Ready to see your plan?',
        description:
          "With the help of our <b>psychologists, coaches,</b> and <b>behavioral specialists</b> our plans have helped thousands of our users meet their personal goals. We're confident that we can help you <b>reach your full potential.</b></div>",
        button: 'Continue',
      },

      {
        image: '/static/icons/4.svg',
        question: 'How much time do you want to spend on self-development every day?',
        questionCode: 'amount_of_daily_time',
        answers: ['5 min / day', '10 min / day', '15 min / day', '20+ min / day'],
        type: 'question',
      },
      {
        type: 'email',
      },
    ],
  },
  GetStarted: {
    description:
      'We have created a personalized plan specifically for you to improve your emotional well-being and manage your ADHD symptoms.',
    button: 'Continue',
  },
  Summary: {
    heading: 'Find your ultimate routine and make it stick',
    resultsTitle: 'Procrastination score',
    suggestedPlan: 'Rewire your ADHD brain',
    issue: 'ADHD symptoms',
    summary: [
      {
        heading: 'Feeling overwhelmed',
        description:
          'You may be struggling to manage symptoms and emotional difficulties, causing feelings of exhaustion.',
      },
      {
        heading: 'Lack of motivation and energy',
        description:
          'You may be struggling with a lack of motivation and energy, potentially due to difficulties managing symptoms.',
      },
      {
        heading: 'Decreased productivity',
        description:
          'You may be experiencing a decrease in productivity and difficulty focusing, which could be indicative of symptoms.',
      },
      {
        heading: 'Emotional difficulties',
        description:
          'You may be struggling with emotional difficulties which could be related to symptoms.',
      },
    ],
  },
  Purchase: {
    suggestedPlan: 'personalized ADHD management',
    promise: 'reduce ADHD symptoms',
    benefits: [
      {
        heading: 'Increase your efficiency.',
        description:
          'Discover how to achieve your goals faster and with less effort by leveraging your strengths.',
      },
      {
        heading: 'Boost your determination.',
        description:
          'Strengthen your self-control mechanisms and develop effective planning strategies for your daily tasks and pleasures.',
      },
      {
        heading: 'Reduce stress and anxiety.',
        description:
          'Learn new ways to identify and manage stressors, and overcome moments of anxiety.',
      },
      {
        heading: 'Transform into a better version of yourself.',
        description:
          'Focus on long-term behavioral improvements and streamline your decision-making process for a stress-free life.',
      },
      {
        heading: 'Enhance your creativity.',
        description:
          'Learn techniques to harness your creativity and turn it into a productive force that benefits you in all aspects of your life.',
      },
      {
        heading: 'Develop meaningful relationships.',
        description:
          'Improve your communication skills and empathy to build stronger and more fulfilling connections with others.',
      },
    ],
    chart: {
      start: 'Current Symptom Severity',
      end: 'Target Symptom Severity',
    },
    newspaper:
      'Our personalized plan will help you learn effective strategies for managing symptoms of ADHD and maintaining a healthy work-life balance. Take control of your life and start thriving today.',
    scienceBacked: [
      {
        image: '/static/icons/bed.png',
        heading: 'Improved sleep quality',
        description:
          'Effective self-care and stress management strategies can lead to improved sleep quality and reduced symptoms of ADHD.',
      },
      {
        image: '/static/icons/relieved.png',
        heading: 'Reduced stress and anxiety',
        description:
          'Managing symptoms of ADHD can lead to reduced stress and anxiety, and improved daily functioning.',
      },
      {
        image: '/static/icons/cash.png',
        heading: 'Increased productivity',
        description:
          'Effective self-care and stress management strategies can lead to increased productivity and improved decision making.',
      },
      {
        image: '/static/icons/brain.png',
        heading: 'Improved cognitive ability',
        description:
          'Effective self-care and stress management strategies can lead to improved cognitive ability and reduced symptoms of ADHD.',
      },
    ],
  },
};

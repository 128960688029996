export const narcissistic = {
  Name: 'narcissistic',
  Landing: {
    heading: 'Recover from narcissistic abuse, heal trust wounds and subconscious mind',
    description:
      'Take a brief test to explore your mental health in the aftermath of narcissistic abuse. Identify your needs, life goals, and adaptive strategies. Get a tailored plan to overcome self-doubt, quiet the inner critic, and overcome negativity.',
  },
  Quiz: {
    quizFlow: [
      {
        image: '/static/icons/1.svg',
        question: 'What is your age?',
        questionCode: 'age',
        subtitle:
          'This information will help tailor support for survivors of narcissistic abuse.',
        answers: [
          '18 - 24 years',
          '25 - 34 years',
          '35 - 44 years',
          '45 - 54 years',
          '55+ years',
        ],
        type: 'question',
      },
      {
        image: '/static/illusions/1.png',
        question: 'What did you see first?',
        questionCode: 'illusion1_first_seen',
        answers: ['2 people', 'A kangaroo'],
        type: 'question',
      },
      {
        image: '/static/illusions/2.png',
        question: 'What did you see first?',
        questionCode: 'illusion2_first_seen',
        answers: ['A city', 'An umbrella'],
        type: 'question',
      },
      {
        image: '/static/illusions/3.png',
        question: 'What did you see first?',
        questionCode: 'illusion3_first_seen',
        answers: ['2 cats', 'A dog'],
        type: 'question',
      },
      {
        image: '/static/illusions/4.png',
        question: 'What did you see first?',
        questionCode: 'illusion4_first_seen',
        answers: ['A shark', 'A leg'],
        type: 'question',
      },
      {
        image: '/static/illusions/5.png',
        question: 'What did you see first?',
        questionCode: 'illusion5_first_seen',
        answers: ['An apple', 'Two faces'],
        type: 'question',
      },
      {
        image: '/static/icons/9.svg',
        questionCode: 'emotional_recovery_duration',
        question:
          'How long have you been dealing with the aftermath of narcissistic abuse?',
        subtitle: 'Understanding the duration helps us tailor your recovery path.',
        answers: [
          '0-12 months',
          '1-3 years',
          'For as long as I can remember',
          "I haven't experienced narcissistic abuse",
        ],
        type: 'question',
      },
      {
        image: '/static/icons/3.svg',
        questionCode: 'insecurity_in_social_situations',
        question: "I don't think I will ever be important or successful.",
        subtitle: 'Do you agree with this statement?',
        answers: ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'],
        type: 'question',
      },
      {
        type: 'info',
        description:
          'Feeling isolated in your struggles is a common aftermath of narcissistic abuse. Doubting your self-worth can lead to stress, anxiety, and a cycle of negative thinking. \n\nStruggling to improve how you see yourself? It might be tied to not fully understanding the impact of past abuse on your personality. \n\nRebuilding your self-esteem begins with recognizing the effects of narcissistic abuse. Adopting recovery strategies tailored to your experience is crucial for healing. \n\nFrom the Well-being Research Team\nat Minderse',
        button: 'Continue',
      },
      {
        image: '/static/icons/7.svg',
        question: 'Why do you think you have emotional instability?',
        questionCode: 'reasons_emotional_instability',
        subtitle: 'Choose all the options that apply to you:',
        answers: [
          'Harmful relationships',
          'Bad experiences in childhood',
          'Being male or female',
          'How your body looks',
          'Social media',
          'Something else',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/5.svg',
        question: 'Others will only like me if I am attractive and do well in life',
        questionCode: 'self_worth_perception',
        subtitle: 'Do you agree with this idea?',
        answers: ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'],
        type: 'question',
      },
      {
        image: '/static/icons/6.svg',
        question: 'I feel good about my appearance',
        questionCode: 'satisfaction_with_looks',
        subtitle: 'Do you agree with this statement?',
        answers: ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'],
        type: 'question',
      },
      {
        image: '/static/icons/7.svg',
        question: 'What drives you to heal from the effects of narcissistic abuse?',
        questionCode: 'motivation_for_healing',
        subtitle: 'Choose all the options that apply to you:',
        answers: [
          'To reclaim my sense of self',
          'To diminish self-doubt and regain confidence',
          'To enhance my overall well-being',
          'To heal from anxiety and depression linked to abuse',
          'To foster healthy and supportive relationships',
          'To stop self-criticism for past vulnerabilities',
          'Something else',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/icons/brenda.png',
        heading: 'NEXT LEVEL SELF-WORTH!',
        description:
          '"I used to feel inferior and powerless, but Minderse gave me the tools to change that. My self-confidence has greatly improved, and I now feel comfortable in myself and capable of achieving my goals.\r\n\r\nIf you\'re struggling with low self-worth, I highly recommend giving Minderse a try."',
        button: 'Continue',
      },
      {
        image: '/static/icons/1.svg',
        question: 'What do you feel you lack due to the aftermath of narcissistic abuse?',
        questionCode: 'impacts_of_narcissistic_abuse',
        subtitle: 'Select all that apply to you:',
        answers: [
          'Realizing my full potential',
          'Regaining self-worth',
          'Moving beyond past trauma',
          'Rebuilding a positive self-image',
          'Fostering self-acceptance and love',
          'Dealing with rejection effectively',
          'Other',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/8.svg',
        question:
          'In what parts of your life do you think healing from narcissistic abuse would help the most?',
        questionCode: 'benefits_of_recovery',
        answers: [
          'My emotional resilience',
          'My intimate and family relationships',
          'My professional life and financial stability',
          'My interactions and social life',
          'Something else',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        question: 'Do you have any habits you want to stop?',
        questionCode: 'habits_to_break',
        subtitle: 'Choose any that apply to you.',
        answers: [
          {
            image: '/static/icons/alcohol.png',
            text: 'Drinking alcohol',
          },
          {
            image: '/static/icons/social-media.png',
            text: 'Screen time',
          },
          {
            image: '/static/icons/sad.png',
            text: 'Delaying tasks',
          },
          {
            image: '/static/icons/hamburger.png',
            text: 'Eating junk food',
          },
          {
            image: '/static/icons/clock.png',
            text: 'Not enough sleep',
          },
          {
            image: '/static/icons/biting.png',
            text: 'Biting nails',
          },
          {
            image: '/static/icons/doubt.png',
            text: 'Doubting myself',
          },
          {
            image: '/static/icons/none.png',
            text: 'None or other',
          },
        ],
        isImageType: true,
        isMultiSelect: true,
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/icons/stats.png',
        heading: 'Ready to see your plan?',
        description:
          "Minderse's behavior change plans are grounded in the latest behavioral science research and expertly crafted by psychologists, coaches, and behavioral specialists.\r\n\r\nOur personalized approach targets the underlying causes of behavior, enabling long-term, sustainable habit change.",
        button: 'Continue',
      },
      {
        image: '/static/icons/2.svg',
        question: 'Share what you would like to focus on in your plan.',
        subtitle: 'Choose any number of options.',
        isMultiSelect: true,
        questionCode: 'focus_areas',
        answers: [
          'Gaining emotional control',
          'Creating strong and meaningful connections',
          'Boosting self-confidence',
          'Reaching your utmost potential',
          'Discovering true inner calm',
          'Overcoming issues with trusting others',
          'Something else',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/1.svg',
        question:
          'How much time can you dedicate each day to working on self-improvement?',
        questionCode: 'daily_commitment_time',
        answers: [
          '5 minutes per day',
          '10 minutes per day',
          '15 minutes per day',
          'More than 20 minutes per day',
        ],
        type: 'question',
      },
      {
        type: 'email',
      },
    ],
  },

  GetStarted: {
    description:
      'We have crafted a personalized plan specifically for you to navigate and heal from the effects of narcissistic abuse, aiming to guide you towards a more balanced and fulfilling life.',
    button: 'Continue',
  },
  Summary: {
    heading: 'Discover your personal growth plan',
    resultsTitle: 'Inner critic score',
    suggestedPlan: 'Healing & Empowerment',
    issue: 'effects of narcissistic abuse',
    summary: [
      {
        heading: 'Rejection anxiety',
        description:
          'You may be limiting yourself and holding yourself back because of a fear of being rejected.',
      },
      {
        heading: 'Overwhelming self-doubt',
        description:
          'Based on your scores, it seems that you have low self-confidence in yourself and your abilities.',
      },
      {
        heading: 'Perfectionism',
        description:
          'It appears that you might be a perfectionist and are never quite satisfied with your work.',
      },
      {
        heading: 'Powerful inner critic',
        description:
          'You frequently criticize yourself harshly for any deviations from your plans.',
      },
    ],
  },
  Purchase: {
    suggestedPlan: 'Recovery and Growth',
    promise: 'enhance resilience and heal from narcissistic abuse',
    subtitle:
      'Regain confidence and self-worth, build strong relationships, alleviate anxiety',
    benefits: [
      {
        heading: 'Build confidence.',
        description:
          'Develop a positive self-image and the skills to overcome obstacles with confidence.',
      },
      {
        heading: 'Boost your efficiency.',
        description: 'Achieve goals faster and easier by leveraging your strengths.',
      },
      {
        heading: 'Strengthen determination.',
        description: 'Develop effective planning strategies and self-control mechanisms.',
      },
      {
        heading: 'Reduce stress and anxiety.',
        description: 'Learn to identify and manage stressors and overcome anxiety.',
      },
      {
        heading: 'Transform into your best self.',
        description: 'Focus on long-term improvements for a stress-free life.',
      },
      {
        heading: 'Build fulfilling relationships.',
        description:
          'Improve communication skills and empathy to connect better with others.',
      },
    ],
    chart: {
      start: 'Your Insecurity Level',
      end: 'Your Target Level',
    },
    newspaper:
      'Transform your life and reach your goals with a customized plan to eliminate self-doubt and improve your relationships, social life, and career.\n \nTake the first step now towards becoming your best self.',
    scienceBacked: [
      {
        image: '/static/icons/bed.png',
        heading: 'Improved sleep quality',
        description:
          'Low self-confidence can seriously disrupt your sleep and significantly reduce sleep quality by at least 36%.',
      },
      {
        image: '/static/icons/relieved.png',
        heading: 'Boosted positive feelings',
        description:
          'Embrace your true self and experience the joy of increased happiness with self-acceptance.',
      },
      {
        image: '/static/icons/cash.png',
        heading: 'Earn more money',
        description:
          'Taking care of mental health leads to increased productivity, better decision making, and improved relationships, leading to greater success and earning potential.',
      },
      {
        image: '/static/icons/brain.png',
        heading: 'Increased cognitive ability',
        description:
          'Enhancing self-acceptance has been shown to increase cognitive processing power, according to a study conducted by scientists in Utah.',
      },
    ],
  },
};

export const npd = {
  Name: 'npd',
  Landing: {
    heading: 'Heal from narcissistic personality disorder',
    description:
      "Take a short test to gain insights into your inner child’s needs and discover your life's purpose. Receive a plan to overcome self-sabotage, silence your inner critic, and transform negativity for a brighter future.",
  },
  Quiz: {
    quizFlow: [
      {
        image: '/static/icons/1.svg',
        question: 'What is your age?',
        questionCode: 'age',
        subtitle: 'This information will be used to analyze your personality.',
        answers: [
          '18 - 24 years',
          '25 - 34 years',
          '35 - 44 years',
          '45 - 54 years',
          '55+ years',
        ],
        type: 'question',
      },
      {
        image: '/static/illusions/1.png',
        question: 'What did you see first?',
        questionCode: 'illusion1_first_seen',
        answers: ['2 people', 'A kangaroo'],
        type: 'question',
      },
      {
        image: '/static/illusions/2.png',
        question: 'What did you see first?',
        questionCode: 'illusion2_first_seen',
        answers: ['A city', 'An umbrella'],
        type: 'question',
      },
      {
        image: '/static/illusions/3.png',
        question: 'What did you see first?',
        questionCode: 'illusion3_first_seen',
        answers: ['2 cats', 'A dog'],
        type: 'question',
      },
      {
        image: '/static/illusions/4.png',
        question: 'What did you see first?',
        questionCode: 'illusion4_first_seen',
        answers: ['A shark', 'A leg'],
        type: 'question',
      },
      {
        image: '/static/illusions/5.png',
        question: 'What did you see first?',
        questionCode: 'illusion5_first_seen',
        answers: ['An apple', 'Two faces'],
        type: 'question',
      },
      {
        image: '/static/icons/9.svg',
        questionCode: 'narcissistic_effects_duration',
        question:
          'For how long have you noticed the effects of narcissistic experiences on your life?',
        subtitle: 'This will help us understand the impact of these experiences.',
        answers: [
          '0-12 months',
          '12-36 months',
          'As long as I can remember',
          "I don't struggle with low self-esteem",
        ],
        type: 'question',
      },
      {
        image: '/static/icons/3.svg',
        questionCode: 'insecurity_in_social_situations',
        question:
          'I feel insignificant and believe I will never achieve anything significant.',
        subtitle: 'Do you agree with the following statement?',
        answers: ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'],
        type: 'question',
      },
      {
        image: '/static/icons/4.svg',
        question: 'What do you struggle with due to narcissistic experiences?',
        questionCode: 'struggles_due_to_narcissistic',
        subtitle: 'Check all that might apply to you:',
        answers: [
          'Feeling down when criticized by others',
          'Focusing on my perceived weaknesses or shortcomings',
          'Feeling sad, depressed, or anxious',
          'Failing to meet my goals',
          'Engaging in people-pleasing behaviors',
          'Other',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        type: 'info',
        description:
          "“You’re not alone – experiencing narcissistic personality disorder often leads to stress, guilt, and anxiety, resulting in a diminished sense of self-worth and missed opportunities. These factors can further perpetuate negative thinking patterns.\n\n If you've struggled to overcome these challenges in the past, it's likely because you haven't fully understood your unique situation. To cultivate lasting self-compassion, it's crucial to gain a deep understanding of your specific needs and then implement a tailored approach that caters to your individual circumstances.”\n\nScience and Research Team\nat Minderse",
        button: 'Continue',
      },
      {
        image: '/static/icons/7.svg',
        question: 'What do you believe contributes to your diminished self-esteem?',
        questionCode: 'reasons_low_self_esteem',
        subtitle: 'Select all that apply:',
        answers: [
          'Toxic relationships',
          'narcissistic personality disorder',
          'Gender',
          'Body image',
          'Social media',
          'Other',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/5.svg',
        question: "People will only like me if I'm good-looking and successful",
        questionCode: 'criticism_response',
        subtitle: 'Do you agree with the following statement?',
        answers: ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'],
        type: 'question',
      },
      {
        image: '/static/icons/6.svg',
        question: 'I am happy with the way I look',
        questionCode: 'happiness_with_appearance',
        subtitle: 'Do you agree with the following statement?',
        answers: ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'],
        type: 'question',
      },
      {
        image: '/static/icons/7.svg',
        question:
          'Why are you embarking on this journey to heal from narcissistic personality disorder?',
        questionCode: 'reasons_for_healing_journey',
        subtitle: 'Check all that might apply to you:',
        answers: [
          'To reclaim my self-worth',
          'To break free from toxic patterns',
          'To regain control of my life',
          'To overcome anxiety and depression',
          'To cultivate healthy and fulfilling relationships',
          'To stop self-criticism and self-blame',
          'Other',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/icons/brenda.png',
        heading: 'EMPOWER YOUR SELF-WORTH!',
        description:
          "“Feeling unseen and underappreciated used to be my daily struggle. But thanks to Minderse, I've learned to value myself. I'm more confident and authentic now - living life my way, not just for applause.“",
        button: 'Continue',
      },
      {
        image: '/static/icons/1.svg',
        question:
          'What do you feel you are missing as a result of narcissistic personality disorder?',
        questionCode: 'missing_due_to_abuse',
        subtitle: 'Choose as many as you like:',
        answers: [
          'Reconnecting with my true self',
          'Feeling worthy and deserving',
          'Letting go of past trauma',
          'Rebuilding my self-image',
          'Embracing self-compassion and self-love',
          'Developing resilience to rejection',
          'Other',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        question: 'Are there any habits you’d like to quit?',
        questionCode: 'quit_habits',
        subtitle: 'Select as many options as you wish.',
        answers: [
          {
            image: '/static/icons/alcohol.png',
            text: 'Drinking',
          },
          {
            image: '/static/icons/social-media.png',
            text: 'Social media',
          },
          {
            image: '/static/icons/sad.png',
            text: 'Procrastination',
          },
          {
            image: '/static/icons/hamburger.png',
            text: 'Fast food',
          },
          {
            image: '/static/icons/clock.png',
            text: 'Losing sleep',
          },
          {
            image: '/static/icons/biting.png',
            text: 'Nail-biting',
          },
          {
            image: '/static/icons/doubt.png',
            text: 'Doubting yourself',
          },
          {
            image: '/static/icons/none.png',
            text: 'None/Other',
          },
        ],
        isImageType: true,
        isMultiSelect: true,
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/icons/stats.png',
        heading: 'Ready to see your plan?',
        description:
          "Minderse's behavior change plans are grounded in the latest behavioral science research and expertly crafted by psychologists, coaches, and behavioral specialists.\r\n\r\nOur personalized approach targets the underlying causes of behavior, enabling long-term, sustainable habit change.",
        button: 'Continue',
      },
      {
        image: '/static/icons/2.svg',
        question: 'Tell us what interests you in your healing journey.',
        subtitle: 'Select as many options as you wish.',
        isMultiSelect: true,
        questionCode: 'what_interests',
        answers: [
          'Reclaiming my personal power',
          'Establishing healthy boundaries',
          'Building authentic self-confidence',
          'Unlocking my true potential',
          'Attaining inner peace and serenity',
          'Rebuilding trust in myself and others',
          'Other',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/1.svg',
        question:
          'What is the amount of time you are willing to commit to self-improvement on a daily basis?',
        questionCode: 'amount_of_daily_time',
        answers: ['5 min / day', '10 min / day', '15 min / day', '20+ min / day'],
        type: 'question',
      },
      {
        type: 'email',
      },
    ],
  },
  GetStarted: {
    description:
      'We have created a personalized plan specifically for you to heal from narcissistic personality disorder and achieve your goals.',
    button: 'Continue',
  },
  Summary: {
    heading: 'Discover your personal growth plan',
    resultsTitle: 'Insecurity score',
    suggestedPlan: 'Narcissism Recovery',
    issue: 'narcissistic personality disorder',
    summary: [
      {
        heading: 'Fear of rejection',
        description:
          'You may be experiencing a fear of rejection, which limits your potential and holds you back from fully expressing yourself.',
      },
      {
        heading: 'Persistent self-doubt',
        description:
          'Based on your scores, it appears that you struggle with persistent self-doubt, doubting your worth and abilities.',
      },
      {
        heading: 'Perfectionism',
        description:
          'It seems that you tend to strive for unattainable perfection, which can create dissatisfaction with your achievements.',
      },
      {
        heading: 'Critical self-judgment',
        description:
          'You have a powerful inner critic that frequently harshly judges and criticizes you, undermining your self-esteem and confidence.',
      },
    ],
  },
  Purchase: {
    suggestedPlan: 'Self-Improvement',
    promise: 'boost self-esteem and develop healthier relationships',
    subtitle:
      'Feel more confident and secure, form healthy bonds, reduce anxious thoughts',
    benefits: [
      {
        heading: 'Build confidence.',
        description:
          'Develop a positive self-image and the skills to overcome obstacles with confidence.',
      },
      {
        heading: 'Boost your efficiency.',
        description: 'Achieve goals faster and easier by leveraging your strengths.',
      },
      {
        heading: 'Strengthen determination.',
        description: 'Develop effective planning strategies and self-control mechanisms.',
      },
      {
        heading: 'Reduce stress and anxiety.',
        description: 'Learn to identify and manage stressors and overcome anxiety.',
      },
      {
        heading: 'Transform into your best self.',
        description: 'Focus on long-term improvements for a stress-free life.',
      },
      {
        heading: 'Build fulfilling relationships.',
        description:
          'Improve communication skills and empathy to connect better with others.',
      },
    ],
    chart: {
      start: 'Your Insecurity Level',
      end: 'Your Target Level',
    },
    newspaper:
      'Transform your life and reach your goals with a customized plan to eliminate self-doubt and improve your relationships, social life, and career.\n \nTake the first step now towards becoming your best self.',
    scienceBacked: [
      {
        image: '/static/icons/bed.png',
        heading: 'Improved sleep quality',
        description:
          'Low self-confidence can seriously disrupt your sleep and significantly reduce sleep quality by at least 36%.',
      },
      {
        image: '/static/icons/relieved.png',
        heading: 'Boosted positive feelings',
        description:
          'Embrace your true self and experience the joy of increased happiness with self-acceptance.',
      },
      {
        image: '/static/icons/cash.png',
        heading: 'Earn more money',
        description:
          'Taking care of mental health leads to increased productivity, better decision making, and improved relationships, leading to greater success and earning potential.',
      },
      {
        image: '/static/icons/brain.png',
        heading: 'Increased cognitive ability',
        description:
          'Enhancing self-acceptance has been shown to increase cognitive processing power, according to a study conducted by scientists in Utah.',
      },
    ],
  },
};

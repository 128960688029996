export const habits = {
  Name: 'habits',
  Landing: {
    heading: 'Build habits in a fun and easy way to boost productivity',
    description:
      'Take a quick quiz to receive your personalized habit-building plan and reach your goals with increased focus, energy, and willpower.',
  },
  Quiz: {
    quizFlow: [
      {
        image: '/static/icons/1.svg',
        question: 'What is your age?',
        questionCode: 'age',
        subtitle:
          'This information will be used to provide personalized recommendations.',
        answers: [
          '18 - 24 years',
          '25 - 34 years',
          '35 - 44 years',
          '45 - 54 years',
          '55+ years',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/2.svg',
        question: 'How often do you find yourself procrastinating or getting distracted?',
        questionCode: 'procrastination_frequency',
        answers: ['All the time', 'Frequently', 'Rarely', 'Never'],
        type: 'question',
      },
      {
        image: '/static/icons/9.svg',
        questionCode: 'agree_statement_1',
        question: 'Do you agree with the following statement?',
        subtitle: '"I feel like I could be more productive if I had better habits."',
        answers: ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'],
        type: 'question',
      },
      {
        image: '/static/icons/3.svg',
        questionCode: 'habits_building_difficulty',
        question: 'How difficult do you find it to build new habits?',
        answers: [
          'Very difficult',
          'Somewhat difficult',
          'Neither difficult nor easy',
          'Somewhat easy',
          'Very easy',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/4.svg',
        question: 'Which of the following areas would you like to improve the most?',
        questionCode: 'areas_of_improvement',
        subtitle: 'Select one or more options.',
        answers: [
          'Productivity',
          'Health and wellness',
          'Relationships',
          'Finances',
          'Career',
          'Personal development',
          'Other',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/1.svg',
        question: 'What is the main reason you want to improve your habits?',
        questionCode: 'habits_improvement_reason',
        answers: [
          'To be more productive',
          'To have better work-life balance',
          'To improve my physical or mental health',
          'To achieve personal goals',
          'Other',
        ],
        type: 'question',
      },

      {
        image: '/static/icons/8.svg',
        question: 'Do you find it easy to stick to new habits?',
        questionCode: 'habit_sticking_ability',
        subtitle: 'Select the option that best describes you.',
        answers: [
          'Very easy',
          'Somewhat easy',
          'Neutral',
          'Somewhat difficult',
          'Very difficult',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/7.svg',
        question: 'What area of your life would you like to improve productivity in?',
        questionCode: 'productivity_area',
        subtitle: 'Select the option that best describes you.',
        answers: [
          'Work/Career',
          'Education/Learning',
          'Health/Fitness',
          'Relationships',
          'Personal Growth',
          'Other',
        ],
        type: 'question',
      },
      {
        type: 'info',
        description:
          '“Congratulations on taking the first step towards building better habits and increasing your productivity!\n\nTo help you achieve your goals, it is important to understand your current habits and develop a plan that fits your unique needs and lifestyle. Let’s get started!”\n\nThe Mindset and Habits Team',
        button: 'Continue',
      },
      {
        image: '/static/icons/5.svg',
        question: 'When faced with obstacles, what is your typical response?',
        questionCode: 'obstacle_response',
        subtitle: 'Select as many or few options as you like.',
        answers: [
          'I give up easily',
          'I get frustrated and angry',
          'I seek guidance or support',
          'I find creative solutions',
          'Other',
        ],
        isMultiSelect: true,
        type: 'question',
      },
      {
        image: '/static/icons/6.svg',
        question: 'How much time do you dedicate to self-improvement each week?',
        questionCode: 'self_improvement_time',
        subtitle: 'Select the option that best describes you.',
        answers: [
          'Less than 1 hour',
          '1-3 hours',
          '4-6 hours',
          '7-10 hours',
          'More than 10 hours',
        ],
        type: 'question',
      },

      {
        type: 'info',
        image: '/static/icons/brenda.png',
        heading: 'EASY & FUN HABITS BUILDING!',
        description:
          '"I used to feel inferior and powerless, but Minderse gave me the tools to change that. My productivity has greatly improved, and I now feel comfortable in myself and capable of achieving my goals.\r\n\r\nIf you\'re struggling with procrastination, I highly recommend giving Minderse a try."',
        button: 'Continue',
      },
      {
        question: 'Which habits or activities do you want to improve or develop?',
        questionCode: 'habits_to_improve',
        subtitle:
          'Please select all of the options that apply to you - this will give us insight into your personal habits.',
        answers: [
          {
            image: '/static/icons/barbell.png',
            text: 'Exercise regularly',
          },
          {
            image: '/static/icons/meditation.png',
            text: 'Meditate daily',
          },
          {
            image: '/static/icons/book.png',
            text: 'Read more',
          },
          {
            image: '/static/icons/water.png',
            text: 'Drink more water',
          },
          {
            image: '/static/icons/writing.png',
            text: 'Write regularly',
          },
          {
            image: '/static/icons/brain2.png',
            text: 'Learn new skills',
          },
          {
            image: '/static/icons/task.png',
            text: 'Plan your day/week',
          },
          {
            image: '/static/icons/fast.png',
            text: 'Boost productivity',
          },
        ],
        isImageType: true,
        isMultiSelect: true,
        type: 'question',
      },

      {
        type: 'info',
        image: '/static/icons/stats.png',
        heading: 'Ready to form better habits and increase productivity?',
        description:
          "Minderse's habit building plans are designed to help you achieve your goals with ease and enjoyment. Our plans are based on the latest behavioral science research and crafted by experts in habit formation.\r\n\r\nLet's get started on building better habits for a more productive you!",
        button: 'Continue',
      },
      {
        image: '/static/icons/2.svg',
        question: 'Tell us what interests you the most in forming better habits.',
        subtitle: 'Select as many options as you wish.',
        isMultiSelect: true,
        questionCode: 'what_interests',
        answers: [
          'Productivity',
          'Self-discipline',
          'Eliminating distractions',
          'Motivation',
          'Getting rid of bad habits',
        ],
        type: 'question',
      },
      {
        image: '/static/icons/1.svg',
        question:
          'How much time per day are you willing to commit to building better habits?',
        questionCode: 'ammount_of_daily_time',
        answers: ['5 minutes', '10 minutes', '15 minutes', '20+ minutes'],
        type: 'question',
      },
      {
        image: '/static/icons/3.svg',
        question:
          'What is your biggest challenge when it comes to building better habits?',
        questionCode: 'biggest_challenge',
        subtitle: 'Select the option that best describes your situation.',
        isMultiSelect: false,
        answers: [
          'I struggle with sticking to my habits',
          'I don’t know how to build better habits',
          'I lack motivation to start and maintain new habits',
          'I find it difficult to break old habits',
          'I have tried habit building in the past but failed',
        ],
        type: 'question',
      },

      {
        type: 'email',
      },
    ],
  },
  GetStarted: {
    description:
      'We have created a personalized plan specifically for you to form habits, increase productivity and achieve your goals.',
    button: 'Continue',
  },
  Summary: {
    heading: 'We utilize Cognitive Behavioral Therapy to ensure lasting results!',
    resultsTitle: 'Procrastination score',
    suggestedPlan: 'habit building',
    issue: 'procrastination',
    summary: [
      {
        heading: 'Inconsistent behavior',
        description:
          'You may struggle with forming effective habits due to inconsistent behavior and difficulty sticking to routines.',
      },
      {
        heading: 'Lack of motivation',
        description:
          'Based on your scores, it appears that you may struggle with finding motivation to start and maintain new habits.',
      },
      {
        heading: 'Procrastination',
        description:
          'It appears that you may struggle with procrastination, leading to difficulty in forming and maintaining effective habits.',
      },
      {
        heading: 'Lack of accountability',
        description:
          'You may struggle with holding yourself accountable for forming and maintaining effective habits, leading to inconsistent results.',
      },
    ],
  },

  Purchase: {
    suggestedPlan: 'Habit Building',
    promise: 'become more productive',
    subtitle: 'Form effective habits, improve time management, and reach your goals',
    benefits: [
      {
        heading: 'Effective Habit Building: Productivity and Growth',
        description:
          'Discover an easy and fun way to form habits that stick, and achieve your goals with the power of effective habit building.',
      },
      {
        heading: 'Improved Time Management: Conquer Procrastination',
        description:
          'Understand the science behind procrastination and overcome it with personalized strategies to improve your time management.',
      },
      {
        heading: 'Reach Your Goals: Stress-Free',
        description:
          'Rediscover your motivation, define your unique strengths, and eliminate stress and anxiety once and for all with effective habit building.',
      },
    ],
    chart: {
      start: 'Your Habit Building Level',
      end: 'Your Target Level',
    },
    newspaper:
      'Transform your life and reach your goals with a customized plan to build effective habits and become more productive.\n \nTake the first step now towards reaching your full potential.',
    scienceBacked: [
      {
        image: '/static/icons/bed.png',
        heading: 'Improved sleep quality',
        description:
          'Effective habit building has been shown to lead to better sleep quality and duration, according to a study conducted by researchers at Stanford University.',
      },
      {
        image: '/static/icons/relieved.png',
        heading: 'Boosted positive feelings',
        description:
          'Experience the joy of progress and accomplishment with effective habit building, leading to increased positive feelings and confidence.',
      },
      {
        image: '/static/icons/cash.png',
        heading: 'Increased earning potential',
        description:
          'Forming effective habits can lead to increased productivity, better decision making, and improved relationships, leading to greater success and earning potential.',
      },
      {
        image: '/static/icons/brain.png',
        heading: 'Improved cognitive ability',
        description:
          'Effective habit building has been shown to improve cognitive processing power, according to a study conducted by scientists in Utah.',
      },
    ],
  },
};
